.login-page{
  min-height: 100vh;
  min-width: 100vw;
  background: url('../public/assets/background-login.png');
  background-size: cover;
}

.footer-defile{
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.form-login{
  width: 60%;
  margin: auto;
}

.c-white{
  color: white;
}

.input-control{
  background: white;
  padding: 0.4em;
  border-radius: 1.8em;
  border: none;
  outline: none;
  margin-top: 1em;
  font-size: large;
  width: 100%;
}

.btn-control{
  background:#EAEEF3;
  color: #07285B;
  font-size: large;
  font-weight: bold;
  border-radius: 1.8em;
  padding: 0.4em;
  border: none;
  outline: none;
  margin-top: 1em;
  padding-left: 3em;
  padding-right: 3em;
}

.err{
  color: red;
}

.header{
  background: #07285B;
}

.w-custom{
  max-width: 80%;
  margin: auto;
}

.dashboard{
  min-width: 100vw;
  min-height: 100vh;
  background: #F1F4F7;
  color: #07285B;
  transition-duration: 0.5s;
}

.card-blue{
  border-radius: 1.6em;
  background: #07285B;
  color: white;
  font-weight: bold;
  padding: 1em;
  text-align: center;
  height: 40vw;
}

.footer-dashboard{
  background: url('../public/assets/background-footer.png');
  bottom: 0;
  width: 100%;
}

.card-date{
  border: solid 2PX #07285B;
  background: #07285B;
  border-radius: 0.8em;
  color: white;
  font-size: 0.6em;
  text-align: center;
  padding: 1em;
  font-weight: bold;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
}

.card-date-active{
  border: solid 2PX #07285B;
  background: #EAEEF3;
  border-radius: 0.8em;
  color:#07285B;
  font-size: 0.6em;
  text-align: center;
  padding: 1em;
  font-weight: bold;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
}

.grid-program{
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.btn-return{
  position: absolutes !important;
  left: 0px;
  top: 0px;
}

.btn-speaker{
  position: fixed;
  left: 10%; 
  top: 5%;
  background: #EAEEF3;
  color: #07285B;
  font-weight: bold;
  font-size: 1.2em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  padding: 1em !important;
  outline: none;
  border: none;
}

.program{
  display: grid;
  grid-template-columns: 20% 60% 20%;
  font-size: 0.6em;
}

.start-h{
  font-size: 1.4em;
  color: #37588B;
  opacity: 0.8;
}

.end-h{
  font-size: 1.4em;
  font-weight: bold;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assure que l'image couvre entièrement le conteneur sans déformation */
  object-position: center; /* Centre l'image à l'intérieur du conteneur */
  position: absolute;
  top: 0;
  left: 0;
}

.image-container {
  width: 100%;  /* La taille du carré */
  height: 100px; /* La taille du carré */
  overflow: hidden; /* Cache les parties excédentaires de l'image */
  position: relative;
}

.speaker-card{
  background: #07285B;
  color: white;
  border-radius: 0.8em;
  overflow: hidden;
  height: 100%;
}

.c-p{
  cursor: pointer;
}

.pop-up{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  transition-duration: 0.5s;
  z-index: 10 !important;
}

.blur{
  filter: blur(0.4rem);
  z-index: 10;
  position: fixed;
  backdrop-filter: blur(0.4rem);
}

.pop-up-container{
  max-width: 80%;
  left: 10%;
  top: 5%;
  height: auto;
  background: #07285B;
  border-radius: 1.4em;
  z-index: 15;
  color: white;
  padding: 1.4em;
  bottom: 5%;
  overflow: scroll;
  transition-duration: 0.5s;
}

.fs-1em{
  font-size: 1em;
}

.container{
  transition-duration: 2s;
}

.bg-d{
  background: #EAEEF3;
}

.row-list{
  font-size: 0.6em;
}

.card-contact{
  background: #07285B;
  border-radius: 1em;
  color: white;
}

.btn-view{
  background: #F1F4F7;
  color: #07285B;
  border: none;
  outline: none;
  margin-top: 1em;
  padding: 0.4em;
  font-size: 1.2em;
  border-radius: 0.4em;
}

.btn-view-qr{
  background: #07285B;
  color: white;
  border: none;
  outline: none;
  margin-top: 1em;
  padding: 0.4em;
  font-size: 1.2em;
  border-radius: 0.4em;
}

.btn-view:hover{
  background: #EAEEF3;
  color: #37588B;
}

.emails-container{
  max-width: 90%;
  margin: auto;
}

.pop-up-num{
  width: 300px;
  height: auto;
  border: solid 1px #07285B;
  background: #F1F4F7;
  color: #07285B;
  padding: 1em;
  border-radius: 0.4em;
}

.t-bold{
  font-weight: bold;
}

.z-index-negatif{
  z-index: -5 !important;
}

.container-car{
  height: 20vh;
  overflow: hidden;
}

.car{
  height: 100%;
  border-radius: 1.5em;
  overflow: hidden;
  padding: 0em;
  background-size: cover;
}

.img-car{
  height: 100%;
  width: 100%;
  object-fit: cover; /* ou contain */
}

.c-light-blue{
  color: #37598bbb;
}

.br{
  border-radius: 1em;
}

.margin-custom{
  margin-top: 5em;
}

.text-underline{
  text-decoration: underline;
}

.hours{
  background: #37598bbb;
  color: white;
}

.partners-title{
  font-weight: bold;
}